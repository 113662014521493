import { EditRounded } from '@mui/icons-material';
import { Button, Chip, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { AppLanguage } from 'features/language/store/types';
import { logWarning } from 'features/logging/logError';
import { EditableComponent } from 'features/modular-workflow/builder/EditableComponent';
import { BuilderError } from 'features/modular-workflow/builder/getBuilderErrorsFromResponse';
import { ModularWorkflowTranslationSwitch } from 'features/modular-workflow/builder/ModularWorkflowTranslationSwitch';
import { InputRenderer } from 'features/modular-workflow/builder/settings/InputRenderer';
import {
  ApiModularWorkflowStepReferenceIcons,
  ApiModularWorkflowStepSettingsValue,
  isLocalizableInput,
  outputAliasRegex
} from 'features/modular-workflow/builder/types-api';
import {
  BuilderModularWorkflowStep,
  BuilderModularWorkflowStepInput,
  BuilderModularWorkflowStepOutput,
  LocalizedObject
} from 'features/modular-workflow/builder/types-builder';
import { useModulareWorkflowCreationStore } from 'features/modular-workflow/builder/useModulareWorkflowCreationStore';
import { useGetModulareWorkflowStepStructureByReference } from 'features/modular-workflow/useModulareWorkflowStructureStore';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

type Props = {
  step: BuilderModularWorkflowStep;
  onSave: (changedStep: BuilderModularWorkflowStep) => void;
  onDirtyChange?: (hasDirtyInputs: boolean) => void;
  headerActions?: ReactNode;
  stepErrorList?: BuilderError[];
};

export const StepContainer = ({
  step,
  onSave,
  onDirtyChange,
  headerActions,
  stepErrorList
}: Props) => {
  // We create a local state with current values to enable "save changes only on confirm click"
  const [inputValues, setInputValues] = useState<
    Record<BuilderModularWorkflowStepInput['reference'], BuilderModularWorkflowStepInput['value']>
  >({});
  const [inputIsEditable, setInputIsEditable] = useState<
    Record<
      BuilderModularWorkflowStepInput['reference'],
      BuilderModularWorkflowStepInput['is_editable']
    >
  >({});
  const getStepStructure = useGetModulareWorkflowStepStructureByReference();
  const currentLanguage = useModulareWorkflowCreationStore(state => state.currentLanguage);

  // Calculate if any input has been changed
  const hasChangedInputs = useMemo(() => {
    // Initial state before inputValues is populated
    if (Object.keys(inputValues).length === 0 && Object.keys(inputIsEditable).length === 0) {
      return false;
    }

    // Check if the editable option is different
    if (step.inputs.some(input => inputIsEditable[input.reference] !== input.is_editable)) {
      return true;
    }

    // Check values
    return step.inputs.some(input => {
      // Localized values are objects, so we need to compare the value from the current language
      if (input.value && isLocalizableInput(input)) {
        const typedNewInputValue = inputValues?.[
          input.reference
        ] as LocalizedObject<ApiModularWorkflowStepSettingsValue>;

        return typedNewInputValue?.[currentLanguage] !== input.value[currentLanguage];
      }

      return inputValues?.[input.reference] !== input.value;
    });
  }, [inputValues, inputIsEditable, step.inputs, currentLanguage]);

  const canSwitchLanguage = useMemo(() => {
    // The .some() callback returns true if the value is empty
    // Therefore a !.some() means, that at least one localizable value is empty
    return !Object.entries(inputValues).some(([reference, value]) => {
      const input = step.inputs.find(input => input.reference === reference);

      // If input is not localizable, we don't need to check for empty values
      if (!input || !isLocalizableInput(input)) {
        return false;
      }

      // Localized value needs to be an object
      if (!value) {
        return true;
      }

      const typedValue = value as LocalizedObject<ApiModularWorkflowStepSettingsValue>;

      return !typedValue?.[currentLanguage];
    });
  }, [currentLanguage, inputValues, step.inputs]);

  // Prefill local state object with current values
  useEffect(() => {
    const valueStateObject: typeof inputValues = {};
    const editableStateObject: typeof inputIsEditable = {};

    step.inputs.forEach(input => {
      valueStateObject[input.reference] = input.value;
      editableStateObject[input.reference] = input.is_editable;
    });

    setInputValues(valueStateObject);
    setInputIsEditable(editableStateObject);
  }, [step, step.inputs]);

  // Report changed inputs to parent component
  useEffect(() => {
    onDirtyChange?.(hasChangedInputs);
  }, [hasChangedInputs, onDirtyChange]);

  const updateStepForOutput = (
    output: BuilderModularWorkflowStepOutput,
    outputIndex: number,
    newName: string
  ) => {
    if (newName === output.alias) {
      return;
    }

    onSave({
      ...step,
      outputs: [
        ...step.outputs.slice(0, outputIndex),
        {
          ...output,
          alias: newName
        },
        ...step.outputs.slice(outputIndex + 1)
      ]
    });
  };

  const handleInputChange = (
    input: BuilderModularWorkflowStepInput,
    value: ApiModularWorkflowStepSettingsValue
  ) => {
    if (isLocalizableInput(input)) {
      if (input.value?.[currentLanguage] === value) {
        return;
      }

      const typedCurrentValues = inputValues[
        input.reference
      ] as LocalizedObject<ApiModularWorkflowStepSettingsValue>;
      setInputValues({
        ...inputValues,
        [input.reference]: {
          ...(typedCurrentValues ?? {}),
          [currentLanguage]: value
        }
      });

      return;
    }

    if (input.value === value) {
      return;
    }

    setInputValues({
      ...inputValues,
      [input.reference]: value
    });
  };

  const handleIsAllowedToBeChangedChange = (
    input: BuilderModularWorkflowStepInput,
    canBeChangedByUser: boolean
  ) => {
    if (input.is_editable === canBeChangedByUser) {
      return;
    }

    setInputIsEditable({
      ...inputIsEditable,
      [input.reference]: canBeChangedByUser
    });
  };

  const handleInputOutputChange = (outputIndex: number, newName: string) => {
    const output = step.outputs[outputIndex];

    updateStepForOutput(output, outputIndex, newName);
  };

  const handleStepOutputChange = (newName: string, output: BuilderModularWorkflowStepOutput) => {
    // inputOutputs is already filtered to match the current input outputs
    // But to update the output name in the list of all outputs for the current step,
    // we need to search the index in the unfiltered list
    const outputIndex = step.outputs.findIndex(o => o.reference === output.reference);
    if (outputIndex === -1) {
      logWarning(new Error('Builder: failed to find output index'), 'modular_workflow', {
        stepReference: step.reference,
        outputReference: output.reference
      });
      return;
    }

    updateStepForOutput(output, outputIndex, newName);
  };

  const handleNameChange = (name: string) => {
    onSave({
      ...step,
      name_localized: {
        ...(step.name_localized ?? {}),
        [currentLanguage]: name
      }
    });
  };

  const handleBeforeSwitchLanguage = (language: AppLanguage) => {
    // If we switch from english to another, prepare the languages as a copy of english
    if (language === AppLanguage.English) {
      return;
    }

    // Copy all english values from inputValues into other languages, if they are empty yet
    const newInputValues = { ...inputValues };
    Object.entries(inputValues)
      // Only consider the values which are localizable
      .filter(([reference]) => step.inputs.some(input => input.reference === reference))
      .forEach(([reference, valueObject]) => {
        const localizableValueObject =
          valueObject as LocalizedObject<ApiModularWorkflowStepSettingsValue>;

        // Already copied or no english value
        if (localizableValueObject[language] || !localizableValueObject[AppLanguage.English]) {
          return;
        }

        newInputValues[reference as keyof typeof inputValues] = {
          ...localizableValueObject,
          [language]: localizableValueObject[AppLanguage.English]
        };
      });

    setInputValues(newInputValues);
  };

  const handleConfirmClick = () => {
    step.inputs.forEach(input => {
      if (!(input.reference in inputValues)) {
        logWarning(new Error('Builder: Failed to find matching input'), 'modular_workflow', {
          inputReference: input.reference,
          inputValues
        });
        return;
      }

      input.value = inputValues[input.reference];
      input.is_editable = inputIsEditable[input.reference] ?? false;
    });

    onSave(step);
  };

  const structure = getStepStructure(step.reference);
  if (!structure) {
    return null;
  }

  const StepTypeIcon = ApiModularWorkflowStepReferenceIcons[step.reference];
  // The output of each field can be renamed on field-level
  // We only want to show the outputs of the step which is not related to a field
  // But the step.outputs contains all of them, therefore we filter them
  const stepOutputsWithoutFields = step.outputs.filter(output => !output.related_input_reference);

  const visibleStepName =
    step.name_localized?.[currentLanguage] ??
    step.name_localized?.[AppLanguage.English] ??
    step.name;

  return (
    <Root>
      <ModularWorkflowTranslationSwitch
        canSwitchLanguage={canSwitchLanguage}
        onBeforeSwitchLanguage={handleBeforeSwitchLanguage}
      />

      <Header>
        <FieldTitle>
          <StepTypeIcon fontSize="small" color="action" />
          <EditableComponent value={visibleStepName} onSave={handleNameChange}>
            <Typography variant="h6">{visibleStepName}</Typography>
            <EditRounded fontSize="small" color="action" />
          </EditableComponent>

          <FlexContainer direction="row">
            {stepOutputsWithoutFields.map(output => (
              <EditableComponent
                key={output.reference}
                value={output.alias}
                onSave={newName => handleStepOutputChange(newName, output)}
                TextFieldProps={{
                  size: 'small',
                  fullWidth: false
                }}
                validationRegex={outputAliasRegex}
              >
                <Chip label={`#${output.alias}`} variant="filled" />
              </EditableComponent>
            ))}
          </FlexContainer>
        </FieldTitle>

        <FieldActions>
          {headerActions}

          <Button
            color="primary"
            variant="contained"
            onClick={handleConfirmClick}
            disabled={!hasChangedInputs}
          >
            <FormattedMessage id="modular_workflow.builder.step.cta_confirm" />
          </Button>
        </FieldActions>
      </Header>

      {step.inputs.map((input, index) => (
        <InputRenderer
          key={`${input.reference}-${step.order}`}
          input={input}
          localInputValue={inputValues[input.reference]}
          step={step}
          stepSettings={structure.settings}
          errorList={stepErrorList?.filter(error => error.inputIndex === index) ?? []}
          onChange={handleInputChange}
          onOutputChange={handleInputOutputChange}
          onIsChangeableChange={handleIsAllowedToBeChangedChange}
        />
      ))}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacings.medium};
  padding: ${({ theme }) => theme.spacings.small};
`;

const Header = styled.div`
  width: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacings.medium};
`;

const FieldTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.small};
`;

const FieldActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.small};
`;

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import { getIsApiCustomer } from 'features/customer/store/selectors';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'store/hooks';

export const ReverseTrialEnforcedLandingPage = (): ReactElement => {
  const navigate = useNavigate();
  const isApiCustomer = useAppSelector(getIsApiCustomer);
  const { data: customerPreferences } = useCustomerPreferences();

  useEffect(() => {
    const shouldSeeOnboarding =
      customerPreferences &&
      !customerPreferences.newUserSurveySeen &&
      !isApiCustomer;

    if (shouldSeeOnboarding) {
      navigate('/onboarding');
    } else {
      navigate('/home');
    }
  }, [customerPreferences, isApiCustomer, navigate]);
  return <div />;
};

export default ReverseTrialEnforcedLandingPage;

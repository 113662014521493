import { getDefaultLogContext } from 'features/logging/getDefaultLogContext';
import { getErrorStackWithOriginalSource } from 'features/logging/getErrorStackWithOriginalSource';
import { logDuringDevelopment } from 'features/logging/logDuringDevelopment';
import { loggingServiceClient } from 'services/backofficeIntegration/http/client/createLoggingServiceClient';
import { isNotProd } from 'utils/isNotProd';

export const logsEndpointUrl = '/logs';

/**
 * According to Artem, these are the available severity levels:
 *  EMERGENCY
 *  ALERT
 *  CRITICAL
 *  ERROR
 *  WARNING
 *  NOTICE
 *  INFO
 *  DEBUG
 *  DEFAULT
 */
export type Severity = 'INFO' | 'WARNING' | 'ERROR';
export type Context = Record<string, unknown>;

type Options = {
  errorOrMessage: Error | string;
  severity: Severity;
  context?: Context;
};

export const sendLogToApi = async ({ errorOrMessage, severity, context = {} }: Options) => {
  const error = typeof errorOrMessage === 'string' ? new Error(errorOrMessage) : errorOrMessage;
  let errorStack = error.stack;

  // Try advancing stack trace with source map locations
  if (errorStack) {
    const advancedStack = await getErrorStackWithOriginalSource(error);
    if (advancedStack) {
      context['meta.error.originalStack'] = errorStack;
      errorStack = advancedStack;
    }
  }

  const nowIsoString = new Date().toISOString();

  const logData = {
    message: error.message,
    severity: severity,
    stack_trace: errorStack || JSON.stringify([]),
    log_source: `web-app-v1-${process.env.REACT_APP_ENV}`,
    timestamp: nowIsoString,
    context: {
      timestamp: nowIsoString,
      ...getDefaultLogContext(),
      ...context
    }
  };

  // Allow faster debugging by logging to console in non-production environments
  if (isNotProd()) {
    logDuringDevelopment(logData);
  }

  // Don't push to log API during local development
  if (location.hostname === 'localhost') {
    return;
  }

  return loggingServiceClient.post(logsEndpointUrl, btoa(JSON.stringify(logData)));
};

import { CircularProgress } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { getRoutePath } from 'config/routes';
import { emailVerified } from 'features/customer/store/actions';
import { getIsAuthenticated } from 'features/customer/store/selectors';
import { getUserData, saveUserDataToStorage } from 'features/customer/store/utils';
import { ErrorScreen } from 'features/VerifyAccount/ErrorScreen';
import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { httpMagicLinkAuth } from 'services/backofficeIntegration/http/endpoints/customer/httpMagicLinkAuth';
import { httpVerifyAccount } from 'services/backofficeIntegration/http/endpoints/customer/httpVerifyAccount';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useURLQuery from 'utils/hooks/useURLQuery';

export function VerifyAccount() {
  const hasExecutedVerificationRef = useRef(false);
  const { token } = useURLQuery();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  const safeToken = token ?? '';

  const loginWithMagicLink = useMutation({
    mutationFn: async () => {
      const result = await httpVerifyAccount.callEndpoint(safeToken);
      if (!result) {
        return;
      }

      const { magicLinkToken: token } = result;

      return httpMagicLinkAuth.callEndpoint(token);
    },
    onSuccess: data => {
      if (!data) {
        return;
      }

      const userData = getUserData(data);
      saveUserDataToStorage(userData, data.is_first_login);
      dispatch(emailVerified(userData));
      navigate(getRoutePath('trialEnforcedLandingPage'));
    }
  });

  useEffect(() => {
    if (isAuthenticated || hasExecutedVerificationRef.current) {
      return;
    }

    hasExecutedVerificationRef.current = true;
    loginWithMagicLink.mutate();
  }, [loginWithMagicLink, isAuthenticated]);

  if (loginWithMagicLink.isError) {
    return <ErrorScreen />;
  }

  return (
    <CenterContainer>
      <CircularProgress />
      <FormattedMessage id="verify_account.verification" />
    </CenterContainer>
  );
}

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ theme }) => theme.spacings.xxxxlarge};

  gap: ${({ theme }) => theme.spacings.medium};
`;

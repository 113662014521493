import { logWarning } from 'features/logging/logError';
import { UpdateRunningModularWorkflowStepsApiResponseError } from 'services/backofficeIntegration/http/endpoints/modularWorkflow/httpUpdateRunningModularWorkflowSteps';
import { assertNonNullable } from 'utils/typescript/nonNullable';

export type BuilderError = {
  stepIndex: number;
  inputIndex: number;
  errorMessage: string | string[];
};

export const getBuilderErrorsFromResponse = (
  response: UpdateRunningModularWorkflowStepsApiResponseError,
  baseStepIndex = 0
): BuilderError[] => {
  if (!response.data) {
    return [];
  }

  return Object.keys(response.data)
    .map(errorKey => {
      assertNonNullable(response.data);

      // Parse step index and input index from error key
      // errorKey is in the format: steps.<index>.inputs.<index>.value
      const match = errorKey.match(/steps\.(\d+)\.inputs\.(\d+)\.value/);
      if (!match) {
        logWarning(new Error('Builder: failed to parse error key'), 'modular_workflow', {
          errorKey,
          errors: JSON.stringify(response.data)
        });
        return null;
      }

      const stepIndex = parseInt(match[1]) + baseStepIndex;
      const inputIndex = parseInt(match[2]);
      const errorMessage = response.data[errorKey];

      return {
        stepIndex,
        inputIndex,
        errorMessage
      };
    })
    .filter(Boolean) as BuilderError[];
};

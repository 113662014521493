import AddIcon from '@mui/icons-material/Add';
import { Button, Typography, TypographyVariant } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { CharacterLimiterWithLabel } from 'components/inputs/CharacterLimiterWithLabel';
import { PrimaryChip } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/NumberOfSuggestionsChips';
import { AiWriterSupportedLanguageAndCountryCode } from 'features/aiWriter/aiWriterSupportedLanguageAndCountryCodes';
import { UserTonalityInput } from 'features/aiWriter/tonality/UserTonalityInput';
import { useTonalityHandlers } from 'features/aiWriter/tonality/useTonalityHandlers';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ComponentProps, ReactNode, useState } from 'react';
import { GtmAttributes } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';
import { withTestId } from 'utils/utils';

type Props = {
  systemTonality: string[];
  userTonality: string[];
  maxLength: number;
  languageAndCountryCode: AiWriterSupportedLanguageAndCountryCode;
  label?: string;
  icon?: ReactNode;
  gtmAttributes?: GtmAttributes;
  onChange: (newSystemTonalities: string[], newUserTonalities: string[]) => void;
  onInputChange?: ComponentProps<typeof UserTonalityInput>['onInputChange'];
  textFieldProps?: ComponentProps<typeof UserTonalityInput>['textFieldProps'];
  size?: 'small' | 'medium';
  titleVariant?: TypographyVariant;
};

export function TonalitySelect({
  systemTonality,
  userTonality,
  maxLength,
  languageAndCountryCode,
  label,
  icon,
  gtmAttributes,
  onChange,
  onInputChange,
  textFieldProps,
  size = 'medium',
  titleVariant = 'body1'
}: Props) {
  const translate = useTr();
  const {
    handleCustomTonalityChange,
    handlePredefinedTonalityClick,
    isDisabled,
    isSelected,
    systemTonalityOptions,
    totalAmountSelected,
    userTonalities,
    maxAmount
  } = useTonalityHandlers({
    systemTonality,
    userTonality,
    languageAndCountryCode,
    maxLength,
    onChange
  });

  const [showCustomTonalityInput, setShowCustomTonalityInput] = useState(false);

  return (
    <CharacterLimiterWithLabel
      label={label ?? translate('common.tonality')}
      icon={icon}
      length={totalAmountSelected}
      maxLength={maxLength}
      titleVariant={titleVariant}
    >
      <ChipContainer gap="small" direction="row">
        {systemTonalityOptions.map(option => (
          <PrimaryChip
            key={option.value}
            label={option.label}
            $selected={isSelected(option)}
            disabled={isDisabled(option)}
            onClick={isDisabled(option) ? undefined : () => handlePredefinedTonalityClick(option)}
            {...gtmAttributes}
            data-tonality={option.value}
            {...withTestId('tonality-chip')}
          />
        ))}
      </ChipContainer>

      {showCustomTonalityInput ? (
        <>
          <UserTonalityInput
            maxAmount={maxAmount}
            userTonalities={userTonalities}
            onChange={handleCustomTonalityChange}
            onInputChange={onInputChange}
            size={size}
            textFieldProps={textFieldProps}
            {...withTestId('custom-tonality-input')}
          />
          <Typography variant="caption">
            <FormattedMessage
              id={
                totalAmountSelected >= maxLength
                  ? 'common.tonality.custom.hint_using_max_amount'
                  : 'common.tonality.custom.hint_press_enter'
              }
              values={{ maxLength }}
            />
          </Typography>
        </>
      ) : (
        <AddCustomTonalitiesButton
          startIcon={<AddIcon />}
          variant="text"
          onClick={() => setShowCustomTonalityInput(true)}
          {...withTestId('add-custom-tonality-button')}
        >
          <FormattedMessage id="common.tonality.custom.cta" />
        </AddCustomTonalitiesButton>
      )}
    </CharacterLimiterWithLabel>
  );
}

const ChipContainer = styled(FlexContainer)`
  flex-wrap: wrap;
`;

const AddCustomTonalitiesButton = styled(Button)`
  align-self: flex-start;
`;

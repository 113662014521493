import { Card, CardContent, Typography } from '@mui/material';
import LocalStorageKey from 'config/localStorageKey';
import { getUserName } from 'features/customer/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ReactElement, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';

import BackgroundDrawing from '../../assets/drawings/doodle.svg';
import IconNeuroflashLogoSVG from '../../assets/neuroflash-logo.svg';
import CheckAnimation from '../../assets/statusAnimation/Check.lottie';
import { getRoutePath } from '../../config/routes';
import { LottieComponent } from '../../features/lottie/LottieComponent';

const autoLoginTime = 2 * 1000;

function useAutoRedirect() {
  const navigate = useNavigate();
  const timeoutRef = useRef<number>();
  const [socialRegisterFlag, setSocialRegisterFlag] = useLocalStorage(
    LocalStorageKey.IsSocialRegisterRoute,
    false
  );

  useEffect(() => {
    if (!timeoutRef.current) {
      timeoutRef.current = window.setTimeout(() => {
        // user can come from social login registration
        if (socialRegisterFlag) {
          navigate(getRoutePath('home'));
          setSocialRegisterFlag(false);
          return;
        }

        navigate(getRoutePath('profileInfo'));
      }, autoLoginTime);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [navigate, setSocialRegisterFlag, socialRegisterFlag]);
}

const PaymentSuccessPage = (): ReactElement => {
  const user = useAppSelector(getUserName);

  useAutoRedirect();

  return (
    <Root>
      <Header>
        <LogoImage src={IconNeuroflashLogoSVG} />
      </Header>
      <Body>
        <Card>
          <CenterCard>
            <LottieComponent src={CheckAnimation} options={{ loop: false, autoplay: true }} />
            <Typography variant="h6">
              <FormattedMessage id="payment_success.headline" />
            </Typography>
            <Typography>
              <FormattedMessage id="payment_success.subline" values={{ user }} />
            </Typography>

            <Typography>
              <FormattedMessage id="payment_success.redirect" />
              <Link to={getRoutePath('home')}>
                <FormattedMessage id="payment_success.link_text" />
              </Link>
              .
            </Typography>
          </CenterCard>
        </Card>
      </Body>
      <Footer>
        <BackgroundImage src={BackgroundDrawing} />
      </Footer>
    </Root>
  );
};

const CenterCard = styled(CardContent)`
  padding: 3em;
  maxwidth: 30em;
`;

const Header = styled.div`
  display: flex;
  justify-content: left;
  padding: 2em;
`;
const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;
const LogoImage = styled.img`
  width: 244px;
`;
const BackgroundImage = styled.img`
  width: 400px;
`;
const Footer = styled.div`
  display: flex;
  justify-content: left;
  max-height: 300px;
  overflow: visible;
`;
export default PaymentSuccessPage;

import { Check } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import { getTrialUsed } from 'features/customer/store/selectors';
import { getPricingPlan } from 'features/pricing/store/selectors';
import { hasSubscriptionEnded } from 'features/pricing/utils/isSubscriptionEnded';
import { isSubscriptionInGrace } from 'features/pricing/utils/isSubscriptionInGrace';
import { isSubscriptionPaymentPending } from 'features/pricing/utils/isSubscriptionPaymentPending';
import { isSubscriptionWithAutoRenewal } from 'features/pricing/utils/isSubscriptionWithAutoRenewal';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomerActiveSubscriptionState } from 'services/api/customer/types';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

type ActivePlanButtonProps = {
  subscriptionState: CustomerActiveSubscriptionState;
  isInLoadingState: boolean;
  onClick: () => void;
};

const ActivePlanButton = ({
  subscriptionState,
  isInLoadingState,
  onClick
}: ActivePlanButtonProps) => {
  const hasAutoRenewal = isSubscriptionWithAutoRenewal(subscriptionState);
  const hasPendingPayment = isSubscriptionPaymentPending(subscriptionState);
  const isOnTrial = subscriptionState.status === 'on trial';
  // If we don't have an active subscription, it must be clickable to renew it
  // If a subscription is pending, you have to wait until it's completed
  const hasClickHandler = !hasAutoRenewal && !hasPendingPayment;
  const isDisabled = hasAutoRenewal || hasPendingPayment || isInLoadingState || isOnTrial;
  const buttonLabel =
    hasAutoRenewal || hasPendingPayment || isOnTrial
      ? 'profile.plan.current_plan'
      : 'profile.plan.current_plan_update_subscription';

  return (
    <StyledButton
      color="primary"
      variant="contained"
      disabled={isDisabled}
      onClick={hasClickHandler ? onClick : undefined}
      startIcon={<Check />}
    >
      <FormattedMessage id={buttonLabel} />

      {isInLoadingState && <SmallLoadingSpinner />}
    </StyledButton>
  );
};

interface Props {
  isBillingInfoPresent: boolean;
  isActivePlan: boolean;
  isLoading: boolean;
  subscriptionState: CustomerActiveSubscriptionState;
  isFreePlan: boolean;
  onCtaClick: () => void;
  isTrial?: boolean;
  isBusinessPlan?: boolean;
}

const PlanConfirmButton = ({
  isActivePlan,
  isLoading,
  subscriptionState,
  isFreePlan,
  onCtaClick,
  isTrial,
  isBusinessPlan
}: Props): ReactElement | null => {
  const trialUsed = useAppSelector(getTrialUsed);
  const pricingPlan = useAppSelector(getPricingPlan);

  if (isActivePlan) {
    return (
      <ActivePlanButton
        subscriptionState={subscriptionState}
        isInLoadingState={isLoading}
        onClick={onCtaClick}
      />
    );
  }

  const isUserOnFreePlan = ['free'].includes(pricingPlan);
  const hasPendingPayment = isSubscriptionPaymentPending(subscriptionState);
  const hasEndedSubscription = hasSubscriptionEnded(subscriptionState);
  const hasGraceSubscription =
    isSubscriptionInGrace(subscriptionState) || subscriptionState.status === 'on trial grace';

  // Disable further plan selection while a payment is pending
  // or the user has an active subscription in grace period
  // Note: Stripe doesn't allow to change your plan while in grace period
  // Disable free plan when user is on a paid one
  const isDisabled =
    isLoading || hasPendingPayment || hasGraceSubscription || (isFreePlan && !hasEndedSubscription);

  const showTrial = isTrial && !trialUsed && isUserOnFreePlan;

  return (
    <StyledButton
      color="primary"
      variant="contained"
      disabled={isDisabled}
      onClick={isDisabled ? undefined : onCtaClick}
    >
      {showTrial ? (
        <FormattedMessage id="pricing_plan.confirm_button.free_trial" />
      ) : isBusinessPlan ? (
        <FormattedMessage id="pricing_plan.confirm_button.contact_expert" />
      ) : (
        <FormattedMessage id="profile.select" />
      )}

      {isLoading && <SmallLoadingSpinner />}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;

const LeftSpacedCircularProgress = styled(CircularProgress)`
  margin-left: ${({ theme }) => theme.spacings.small};
`;

const SmallLoadingSpinner = () => <LeftSpacedCircularProgress size={14} />;

export default PlanConfirmButton;

import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Button, ButtonProps, MobileStepper } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { MouseEvent, ReactNode } from 'react';
import styled from 'styled-components';
import { withTestId } from 'utils/utils';

type Props = {
  stepCount: number;
  children: ReactNode;
  nextButtonProps?: ButtonProps;
  backButtonProps?: ButtonProps;
  activeStep?: number;
  hasBackButton?: boolean;
  hasNextButton?: boolean;
};

export const CommonWorkflowContainer = ({
  children,
  stepCount,
  nextButtonProps,
  backButtonProps,
  activeStep = 0,
  hasBackButton = true,
  hasNextButton = true
}: Props) => {
  const handleNextClick = (event: MouseEvent<HTMLButtonElement>) => {
    nextButtonProps?.onClick?.(event);
  };

  const handleBackClick = (event: MouseEvent<HTMLButtonElement>) => {
    backButtonProps?.onClick?.(event);
  };

  return (
    <Root>
      <StepContainer>{children}</StepContainer>

      <StepperContainer>
        <StyledMobileStepper
          variant="dots"
          steps={stepCount}
          position="static"
          activeStep={activeStep}
          sx={{ width: '100%' }}
          backButton={
            <BackButtonContainer>
              <Button
                {...backButtonProps}
                size="small"
                onClick={handleBackClick}
                disabled={backButtonProps?.disabled || activeStep === 0}
                sx={{ ...(hasBackButton ? {} : { visibility: 'hidden' }) }}
                {...withTestId('back-button')}
              >
                <KeyboardArrowLeft />
                <FormattedMessage id="modular_workflow.common.button_back" />
              </Button>
            </BackButtonContainer>
          }
          nextButton={
            <NextButtonContainer>
              <Button
                {...nextButtonProps}
                size="small"
                onClick={handleNextClick}
                disabled={nextButtonProps?.disabled}
                // To keep layout in place, we need to hide the button instead of render null
                sx={{ ...(hasNextButton ? {} : { visibility: 'hidden' }) }}
                {...withTestId('next-button')}
              >
                <FormattedMessage id="modular_workflow.common.button_next" />
                <KeyboardArrowRight />
              </Button>
            </NextButtonContainer>
          }
        />
      </StepperContainer>
    </Root>
  );
};

export const CommonWorkflowOuterRoot = styled.div`
  margin: auto;
  flex: 0 0 auto;
`;

const Root = styled(FlexContainer).attrs({
  direction: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start'
})`
  margin: auto auto;
  width: 600px;
  min-height: 600px;
  padding: ${({ theme }) => theme.spacings.four};

  border-radius: ${({ theme }) => theme.borderRadius.one};
  border: 1px solid ${({ theme }) => theme.colors.divider};

  background: ${({ theme }) => theme.colors.backgroundPaperElevation4};
`;

const StepContainer = styled(FlexContainer).attrs({
  direction: 'column',
  alignItems: 'flex-start',
  gap: 'four'
})`
  align-self: stretch;
  position: relative;
`;

const StepperContainer = styled.div`
  width: 100%;
`;

const StyledMobileStepper = styled(MobileStepper)`
  background-color: transparent;
`;

const NextButtonContainer = styled(FlexContainer).attrs({
  alignItems: 'flex-end'
})`
  min-width: 120px;
`;

const BackButtonContainer = styled(FlexContainer).attrs({
  alignItems: 'flex-start'
})`
  min-width: 120px;
`;

import { IFRAME_ID, routeIframe } from 'features/brand-hub-v2/useIframeMessageListener';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const iframeUrl = process.env.REACT_APP_BRAND_HUB_V2_URL;
const iframeEntryPoint = '/brand-hub';

export const BrandHubV2IframeWrapper = () => {
  const [iframeRoute, setIframeRoute] = useState('/');

  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    if (url === null) {
      setIframeRoute(iframeEntryPoint);
      setUrl(`${iframeUrl}${iframeEntryPoint}?isEmbedded=true`);
    }
  }, [url]);

  useEffect(() => {
    if (iframeRoute) {
      routeIframe(iframeRoute);
    }
  }, [iframeRoute]);

  if (!url) return null;

  const isActive = Boolean(iframeRoute);
  return (
    <StyledIframe id={IFRAME_ID} title="Embedded App" src={url} $isActive={isActive}></StyledIframe>
  );
};

const StyledIframe = styled.iframe<{ $isActive: boolean }>`
  border: none;
  width: ${({ $isActive }) => ($isActive ? '100%' : '1px')};
  height: ${({ $isActive }) => ($isActive ? '100%' : '1px')};
`;

import { Checkbox, Chip, FormControlLabel, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { logWarning } from 'features/logging/logError';
import { EditableComponent } from 'features/modular-workflow/builder/EditableComponent';
import { InputRendererContext } from 'features/modular-workflow/builder/settings/InputRenderer';
import { outputAliasRegex } from 'features/modular-workflow/builder/types-api';
import { BuilderModularWorkflowStepOutput } from 'features/modular-workflow/builder/types-builder';
import { ReactNode, useContext, useId } from 'react';
import styled from 'styled-components';

type Props = {
  children: (elementId: string) => ReactNode;
  hasEditableCheckbox?: boolean;
  endAdornment?: ReactNode;
};

export const BaseSetting = ({ children, hasEditableCheckbox = true, endAdornment }: Props) => {
  const elementId = useId();

  const {
    step,
    input,
    inputOutputs,
    inputSettings: settings,
    onOutputChange,
    onIsChangeableChange,
    inputErrorList
  } = useContext(InputRendererContext);

  const handleOutputChipChange = (output: BuilderModularWorkflowStepOutput, newName: string) => {
    // inputOutputs is already filtered to match the current input outputs
    // But to update the output name in the list of all outputs for the current step,
    // we need to search the index in the unfiltered list
    const outputIndex = step.outputs.findIndex(o => o.reference === output.reference);
    if (outputIndex === -1) {
      logWarning(new Error('Builder: Failed to find output index'), 'modular_workflow', {
        stepReference: step.reference,
        outputReference: output.reference
      });
      return;
    }

    if (newName === inputOutputs[outputIndex].alias) {
      return;
    }

    onOutputChange(outputIndex, newName);
  };

  return (
    <Root>
      <InputHeader>
        <label className="label" htmlFor={elementId}>
          <Typography variant="subtitle2" color={inputErrorList.length > 0 ? 'red' : undefined}>
            {settings.name}
          </Typography>
        </label>

        <FlexContainer direction="row" alignItems="center" gap="small">
          {endAdornment}

          {inputOutputs.map(output => (
            <EditableComponent
              key={output.reference}
              value={output.alias}
              onSave={newName => handleOutputChipChange(output, newName)}
              TextFieldProps={{
                size: 'small',
                fullWidth: false
              }}
              validationRegex={outputAliasRegex}
            >
              <Chip label={`#${output.alias}`} variant="filled" size="small" />
            </EditableComponent>
          ))}
        </FlexContainer>
      </InputHeader>

      {children(elementId)}

      {hasEditableCheckbox && (
        <FormControlLabel
          control={<Checkbox defaultChecked={input.is_editable} />}
          label={<FormattedMessage id="modular_workflow.builder.common.allow_to_be_change" />}
          onChange={(_event, checked) => onIsChangeableChange(input, checked)}
        />
      )}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
`;

const InputHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacings.medium};
  margin-bottom: ${({ theme }) => theme.spacings.small};
`;

import { RoundedCircularProgressWithCrown } from 'features/flashScore/RoundedCircularProgressWithCrown';
import { RoundedCircularProgressWithLabel } from 'features/flashScore/RoundedCircularProgressWithLabel';
import styled, { css } from 'styled-components';

export const readabilityColorCss = css`
  .MuiCircularProgress-root {
    color: ${({ theme }) => theme.colors.secondaryColorMain};
  }
`;

export const emotionalityColorCss = css`
  .MuiCircularProgress-root {
    color: ${({ theme }) => theme.colors.accentRoseMain};
  }
`;

const BaseRoundedCircularProgressWithLabel = styled(RoundedCircularProgressWithLabel).attrs({
  CircularProgressProps: {
    size: 80,
    thickness: 6,
    variant: 'determinate'
  }
})``;

export const EmotionalityCircularProgress = styled(BaseRoundedCircularProgressWithLabel)`
  ${emotionalityColorCss};
` as typeof RoundedCircularProgressWithLabel;

export const ReadabilityCircularProgress = styled(BaseRoundedCircularProgressWithLabel)`
  ${readabilityColorCss};
` as typeof RoundedCircularProgressWithLabel;

export const SmallerEmotionalityCircularProgress = styled(EmotionalityCircularProgress).attrs({
  CircularProgressProps: {
    size: 24,
    thickness: 7
  }
})``;

export const SmallerReadabilityCircularProgress = styled(ReadabilityCircularProgress).attrs({
  CircularProgressProps: {
    size: 24,
    thickness: 7
  }
})``;

export const SmallerCrownCircularProgress = styled(RoundedCircularProgressWithCrown).attrs({
  CircularProgressProps: {
    size: 24,
    thickness: 7,
  },
  animationRadius: 19
})``;

export const SmallerDisabledCircularProgress = styled(ReadabilityCircularProgress).attrs({
  CircularProgressProps: {
    size: 24,
    thickness: 7
  }

})`
  .MuiCircularProgress-root {
    color: ${({ theme }) => theme.colors.textMuted};
  }
`;

import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { GAEvents } from 'services/tracking/GAEvents';

import Toast from '../../components/toasts/Toast';
import CustomerAPI from '../../services/api/customer';
import { logWarning } from '../logging/logError';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

export function StripeCheckoutForm({ pricingId }: { pricingId: number }) {
  const options = {
    fetchClientSecret: async () => {
      const response = await CustomerAPI.createStripeCheckoutSession(
        pricingId.toString(),
        window.location.protocol +
          '//' +
          window.location.host +
          '/payment-callback/{CHECKOUT_SESSION_ID}'
      );

      if (!response.status) {
        logWarning('Could not start stripe embedded', 'react', {
          status: response.status,
          message: response.message
        });
        Toast.error('app.api_error');
        return '';
      }

      GAEvents.startCheckout({ pricingId });

      return response.data.client_secret;
    }
  };

  return (
    <div id="stripe-checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
}

import { TaskType } from 'services/backofficeIntegration/http/endpoints/onboardingChecklist/httpGetChecklist';
import {
  TrackingEventLoginAttributes,
  TrackingEventRegistrationAttributes,
  TrackingEventUserDataAttributes
} from 'services/tracking/types';

type CustomEventsParams = Record<string, string | number | boolean | undefined | object | null>;

const reportCustomEvent = (eventName: string, params?: CustomEventsParams) => {
  if (!window.dataLayer) {
    // we need this to add events to dataLayer before initializing GA
    // see: https://github.com/alinemorelli/react-gtm/blob/master/src/TagManager.js#L41
    window.dataLayer = [];
  }

  window.dataLayer.push?.({ event: eventName, ...params });
};

const userEvents = () => {
  return {
    userData: (trackingData: TrackingEventUserDataAttributes) =>
      reportCustomEvent('userData', trackingData),
    userLogin: (trackingData: TrackingEventLoginAttributes) =>
      reportCustomEvent('login', trackingData),
    userRegistration: (trackingData: TrackingEventRegistrationAttributes) =>
      reportCustomEvent('registration', trackingData)
  };
};

const pricingEvents = () => {
  const affiliation = 'Neuroflash web app';
  const currency = 'EUR';

  window.dataLayer?.push({ ecommerce: null });

  return {
    purchase: ({
      pricingPlanId,
      pricingPlanName,
      value,
      coupon,
      duration,
      email
    }: {
      pricingPlanId: number;
      pricingPlanName: string;
      value: string;
      coupon: string | null;
      duration: 'year' | 'month';
      email: string;
    }) =>
      reportCustomEvent('purchase', {
        ecommerce: {
          affiliation,
          value,
          currency,
          coupon,
          email,
          items: [
            {
              item_id: pricingPlanId,
              item_name: pricingPlanName,
              affiliation,
              coupon,
              currency,
              price: value,
              quantity: 1,
              duration
            }
          ]
        }
      }),
    registerAndStartTrial: (params?: CustomEventsParams) =>
      reportCustomEvent('registerAndStartTrial', params),
    startTrialFromApp: (params?: CustomEventsParams) =>
      reportCustomEvent('startTrialFromApp', params),
    startCheckout: (params: { pricingId: number }) => reportCustomEvent('startCheckout', params)
  };
};

const keyboardShortcutEvents = () => {
  return {
    usedCustomShortcut: (identifier: string, hotkey?: string) =>
      reportCustomEvent('customKeyboardShortcut', {
        shortcutName: identifier,
        hotkey
      })
  };
};

type GenericFeatureEventNames = 'continue_writing';

type GenericFeatureEventParams = CustomEventsParams & {
  featureName: GenericFeatureEventNames;
};

const genericFeaturesEvents = () => {
  return {
    usedFeature: (params: GenericFeatureEventParams) => reportCustomEvent('usedFeature', params)
  };
};

const getHelperUtils = () => {
  return {
    getClientId: () => {
      const tracker = window.ga?.getAll?.()[0];
      return tracker?.get?.('clientId');
    }
  };
};

export type ChatMessageSource = 'enter' | 'button';

const chatMessageEvents = () => {
  return {
    sentChatMessage: ({ source }: { source: ChatMessageSource }) =>
      reportCustomEvent('sentChatMessage', {
        source
      }),
    gpt4LimitationViewed: (params?: CustomEventsParams) =>
      reportCustomEvent('gpt4LimitationViewed', params),
    stopStreamingClicked: (params?: CustomEventsParams) =>
      reportCustomEvent('stopStreamingClicked', params),
    promptInChatSent: (params?: CustomEventsParams) => reportCustomEvent('promptInChatSent', params)
  };
};

const teamEvents = () => {
  return {
    teamInvitationSent: (params: CustomEventsParams) =>
      reportCustomEvent('teamInvitationSent', params),
    teamInvitationAccepted: (params: CustomEventsParams) =>
      reportCustomEvent('teamInvitationAccepted', params),
    teamInvitationResent: (params: CustomEventsParams) =>
      reportCustomEvent('teamInvitationResent', params),
    teamUserDeactivated: (params: CustomEventsParams) =>
      reportCustomEvent('teamUserDeactivated', params),
    teamUserReactivated: (params: CustomEventsParams) =>
      reportCustomEvent('teamUserReactivated', params),
    teamUserRoleChange: (params: CustomEventsParams) =>
      reportCustomEvent('teamUserRoleChange', params),
    teamUserDeleted: (params: CustomEventsParams) => reportCustomEvent('teamUserDeleted', params)
  };
};

const aiWriterEvents = () => {
  return {
    sidebarExpand: (params?: CustomEventsParams) => reportCustomEvent('sidebarExpand', params),
    sidebarShrink: (params?: CustomEventsParams) => reportCustomEvent('sidebarShrink', params),
    overviewGptSelectOpen: (params?: CustomEventsParams) =>
      reportCustomEvent('overviewGptSelectOpen', params),
    overviewPersonalitySelectOpen: (params?: CustomEventsParams) =>
      reportCustomEvent('overviewPersonalitySelectOpen', params),
    sidebarImagesTabSwitched: (params: CustomEventsParams) =>
      reportCustomEvent('tabSwitched', params),
    sidebarGenerateImageClicked: (params?: CustomEventsParams) =>
      reportCustomEvent('generateImageSidebarClicked', params),
    sidebarImageRatioClicked: (params: CustomEventsParams) =>
      reportCustomEvent('imageRatioSidebarClicked', params),
    sidebarNumberOfImagesClicked: (params: CustomEventsParams) =>
      reportCustomEvent('numberOfImagesSidebarClicked', params),
    sidebarImageQualityClicked: (params: CustomEventsParams) =>
      reportCustomEvent('imageQualityClicked', params),
    editorClickedOnce: (params?: CustomEventsParams) =>
      reportCustomEvent('editorClickedOnce', params),
    editorBackgroundEnabled: (params?: CustomEventsParams) =>
      reportCustomEvent('editorBackgroundEnabled', params),
    editorBackgroundDisabled: (params?: CustomEventsParams) =>
      reportCustomEvent('editorBackgroundDisabled', params),
    editorBackgroundChange: (params?: CustomEventsParams) =>
      reportCustomEvent('editorBackgroundChange', params),
    editorEmptyDocumentCreateEmptyPage: (params?: CustomEventsParams) =>
      reportCustomEvent('aiWriterEditorEmptyDocumentCreateEmptyPage', params),
    editorEmptyDocumentShowTextTypes: (params?: CustomEventsParams) =>
      reportCustomEvent('aiWriterEditorEmptyDocumentShowTextTypes', params),
    editorEmptyDocumentShowChat: (params?: CustomEventsParams) =>
      reportCustomEvent('aiWriterEditorEmptyDocumentShowChat', params),
    editorEmptyDocumentShowAiImages: (params?: CustomEventsParams) =>
      reportCustomEvent('aiWriterEditorEmptyDocumentShowAiImages', params),
    editorEmptyDocumentShowBlogPostWorkflow: (params?: CustomEventsParams) =>
      reportCustomEvent('aiWriterEditorEmptyDocumentShowBlogPostWorkflow', params),
    editorEmptyDocumentShowSocialMediaWorkflow: (params?: CustomEventsParams) =>
      reportCustomEvent('aiWriterEditorEmptyDocumentShowSocialMediaWorkflow', params),
    editorEmptyDocumentRunCustomPrompt: (params?: CustomEventsParams) =>
      reportCustomEvent('aiWriterEditorEmptyDocumentRunCustomPrompt', params),
    editorEmptyDocumentRunPredefinedPrompt: (params?: CustomEventsParams) =>
      reportCustomEvent('aiWriterEditorEmptyDocumentRunPredefinedPrompt', params),
    editorEmptyDocumentRunActionWithEnter: (params?: CustomEventsParams) =>
      reportCustomEvent('aiWriterEditorEmptyDocumentRunActionWithEnter', params),
    editorEmptyDocumentShownAfterEmptying: (params?: CustomEventsParams) =>
      reportCustomEvent('aiWriterEditorEmptyDocumentShownAfterEmptying', params),
    createdNewDocument: (params?: CustomEventsParams) =>
      reportCustomEvent('aiWriterNewDocument', params),
    startedSearchInTextTypesMenu: (params?: CustomEventsParams) =>
      reportCustomEvent('aiWriterStartedSearchInTextTypesMenu', params),
    startedSearchInTextTypesModal: (params?: CustomEventsParams) =>
      reportCustomEvent('aiWriterStartedSearchInTextTypesModal', params),
    editMessageInChatClicked: (params?: CustomEventsParams) =>
      reportCustomEvent('aiWriterEditMessageInChatClicked', params),
    editedMessageInChatSent: (params?: CustomEventsParams) =>
      reportCustomEvent('aiWriterEditedMessageInChatSent', params),
    textInspirationsSort: (params?: CustomEventsParams) =>
      reportCustomEvent('aiWriterTextInspirationsSort', params),
    flashScoreHighlightToggle: (params?: CustomEventsParams) =>
      reportCustomEvent('aiWriterFlashScoreHighlightToggle', params),
    flashScoreHighlightedWordReplace: (params?: CustomEventsParams) =>
      reportCustomEvent('aiWriterFlashScoreHighlightedWordReplace', params)
  };
};

const flashActionEvents = () => {
  return {
    flashActionExecuted: (params: CustomEventsParams) =>
      reportCustomEvent('flashActionExecuted', params),
    flashActionInEditorExecuted: (params: CustomEventsParams) =>
      reportCustomEvent('flashActionInEditorExecuted', params),
    flashActionCreated: (params: CustomEventsParams) =>
      reportCustomEvent('flashActionCreated', params),
    flashActionInputCreatedBySpace: (params?: CustomEventsParams) =>
      reportCustomEvent('flashActionInputCreatedBySpace', params)
  };
};

const onboardingEvents = () => {
  return {
    completeChecklistTask: (task: TaskType) =>
      reportCustomEvent('onboardingChecklistTaskCompleted', { task: task }),
    completeAllChecklistTasks: (params?: CustomEventsParams) =>
      reportCustomEvent('onboardingChecklistAllTasksCompleted', params),
    onboardingPersonalityAnalysisClick: (params?: CustomEventsParams) =>
      reportCustomEvent('onboardingPersonalityAnalysisClick', params),
    onboardingPersonalitySkipClick: (params?: CustomEventsParams) =>
      reportCustomEvent('onboardingPersonalitySkipClick', params),
    onboardingPersonalitySaveClick: (params?: CustomEventsParams) =>
      reportCustomEvent('onboardingPersonalitySaveClick', params),
    onboardingPersonalityResultInputClick: (params?: CustomEventsParams) =>
      reportCustomEvent('onboardingPersonalityResultInputClick', params),
    onboardingSelectionSlideNext: (params?: CustomEventsParams) =>
      reportCustomEvent('onboardingSelectionSlideNext', params),
    onboardingSelectionSlidePrevious: (params?: CustomEventsParams) =>
      reportCustomEvent('onboardingSelectionSlidePrevious', params),
    onboardingSelectionUsePersonalityClick: (params?: CustomEventsParams) =>
      reportCustomEvent('onboardingSelectionUsePersonalityClick', params),
    onboardingSelectionBlogWorkflowClick: (params?: CustomEventsParams) =>
      reportCustomEvent('onboardingSelectionBlogWorkflowClick', params),
    onboardingSelectionSocialWorkflowClick: (params?: CustomEventsParams) =>
      reportCustomEvent('onboardingSelectionSocialWorkflowClick', params),
    onboardingSelectionChatflashClick: (params?: CustomEventsParams) =>
      reportCustomEvent('onboardingSelectionChatflashClick', params),
    onboardingSelectionPromptLibraryClick: (params?: CustomEventsParams) =>
      reportCustomEvent('onboardingSelectionPromptLibraryClick', params),
    onboardingSelectionAiImagesClick: (params?: CustomEventsParams) =>
      reportCustomEvent('onboardingSelectionAiImagesClick', params),
    onboardingSelectionExploreClick: (params?: CustomEventsParams) =>
      reportCustomEvent('onboardingSelectionExploreClick', params)
  };
};

const toolbarEvents = () => {
  return {
    topBarActionExecuted: (params: CustomEventsParams) =>
      reportCustomEvent('topBarActionExecuted', params),
    bottomBarActionExecuted: (params: CustomEventsParams) =>
      reportCustomEvent('bottomBarActionExecuted', params)
  };
};

const modalEvents = () => {
  return {
    modalSeen: (params: CustomEventsParams) => reportCustomEvent('modalSeen', params),
    modalClicked: (params: CustomEventsParams) => reportCustomEvent('modalClicked', params)
  };
};

const imageEvents = () => {
  return {
    imageAdded: (params?: CustomEventsParams) => reportCustomEvent('imageAdded', params),
    downloadImage: (params?: CustomEventsParams) => reportCustomEvent('downloadImage', params)
  };
};

const regularWorkflowEvents = () => {
  return {
    blogPostWorkflowKeywordSubmitted: (params?: CustomEventsParams) =>
      reportCustomEvent('blogPostWorkflowKeywordSubmitted', params),
    blogPostWorkflowElementSelected: (params?: CustomEventsParams) =>
      reportCustomEvent('blogPostWorkflowElementSelected', params)
  };
};
const commonWorkflowEvents = () => {
  return {
    workflowStarted: (params?: CustomEventsParams) => reportCustomEvent('workflow started', params),
    workflowFinished: (params?: CustomEventsParams) =>
      reportCustomEvent('workflow completed', params)
  };
};

const smartSuggestionsEvents = () => {
  return {
    smartSuggestionChosen: (params: CustomEventsParams) =>
      reportCustomEvent('smartSuggestionChosen', params)
  };
};

const faqSectionEvents = () => {
  return {
    FAQquestionClicked: (params: CustomEventsParams) =>
      reportCustomEvent('FAQquestionClicked', params)
  };
};

const textLengthChipsEvents = () => {
  return {
    textLengthChipClicked: (params: CustomEventsParams) =>
      reportCustomEvent('textLengthChipClicked', params)
  };
};

const tagsEvents = () => {
  return {
    tagsIconClickedInDocumentEditor: (params?: CustomEventsParams) =>
      reportCustomEvent('tagsIconClickedInDocumentEditor', params),
    tagHovered: (params?: CustomEventsParams) => reportCustomEvent('tagHovered', params),
    tagClickedInProjectOverview: (params?: CustomEventsParams) =>
      reportCustomEvent('tagClickedInProjectOverview', params),
    tagsInProjectMoreMenuClicked: (params?: CustomEventsParams) =>
      reportCustomEvent('tagsInProjectMoreMenuClicked', params),
    tagSearchInTagsDropdownMenu: (params?: CustomEventsParams) =>
      reportCustomEvent('tagSearchInTagsDropdownMenu', params),
    tagCreatedInTagsDropdownMenu: (params?: CustomEventsParams) =>
      reportCustomEvent('tagCreatedInTagsDropdownMenu', params),
    tagAssignedToDocument: (params?: CustomEventsParams) =>
      reportCustomEvent('tagAssignedToDocument', params),
    tagUnassignedFromDocument: (params?: CustomEventsParams) =>
      reportCustomEvent('tagUnassignedFromDocument', params),
    manageButtonClickedInTagsDropdownMenu: (params?: CustomEventsParams) =>
      reportCustomEvent('manageButtonClickedInTagsDropdownMenu', params),
    tagFilterInProjectOverviewClicked: (params?: CustomEventsParams) =>
      reportCustomEvent('tagFilterInProjectOverviewClicked', params),
    tagCreatedInTagsLibrary: (params?: CustomEventsParams) =>
      reportCustomEvent('tagCreatedInTagsLibrary', params),
    tagDeletedInTagsLibrary: (params?: CustomEventsParams) =>
      reportCustomEvent('tagDeletedInTagsLibrary', params)
  };
};

const inviteFriendEvents = () => {
  return {
    inviteFriedGetShareableLink: (params?: CustomEventsParams) =>
      reportCustomEvent('inviteFriedGetShareableLink', params),
    inviteFriedCopyShareableLink: (params?: CustomEventsParams) =>
      reportCustomEvent('inviteFriedCopyShareableLink', params)
  };
};

const specialPricingLinkEvents = () => {
  return {
    navigatedToSpecialPricingLink: (params?: CustomEventsParams) =>
      reportCustomEvent('navigatedToSpecialPricingLink', params)
  };
};

const brandHubEvents = () => {
  return {
    promptInBrandHubOpened: (params?: CustomEventsParams) =>
      reportCustomEvent('promptInBrandHubOpened', params),
    promptInBrandHubSent: (params?: CustomEventsParams) =>
      reportCustomEvent('promptInBrandHubSent', params)
  };
};

const darkModeEvents = () => {
  return {
    darkModeEnabled: (params?: CustomEventsParams) => reportCustomEvent('darkModeEnabled', params),
    darkModeDisabled: (params?: CustomEventsParams) => reportCustomEvent('darkModeDisabled', params)
  };
};

export const GAEvents = {
  ...userEvents(),
  ...pricingEvents(),
  ...keyboardShortcutEvents(),
  ...genericFeaturesEvents(),
  ...getHelperUtils(),
  ...chatMessageEvents(),
  ...teamEvents(),
  ...aiWriterEvents(),
  ...flashActionEvents(),
  ...onboardingEvents(),
  ...toolbarEvents(),
  ...modalEvents(),
  ...imageEvents(),
  ...commonWorkflowEvents(),
  ...regularWorkflowEvents(),
  ...smartSuggestionsEvents(),
  ...faqSectionEvents(),
  ...textLengthChipsEvents(),
  ...tagsEvents(),
  ...inviteFriendEvents(),
  ...specialPricingLinkEvents(),
  ...brandHubEvents(),
  ...darkModeEvents(),
  reportCustomEvent
};

import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { TextTypeDropdownComponentProps, TextTypesDropdownComponent } from 'features/textGenerator/EnhancedOutputTypeAutocomplete/TextTypesDropdownComponent';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';
import { withTestId } from 'utils/utils';

export function EnhancedOutputTypeAutocomplete(props: TextTypeDropdownComponentProps) {
  const { name, modelCountry, modelLanguage, isDisabled, onSelect, onShowModal } = props;
  const translate = useTr();

  return (
    <FlexContainer>
      <Typography variant="subtitle2">{translate('output_type_modal.textTypes')}</Typography>
      <AutocompleteContainer {...withTestId('textTypesInput')}>
        <TextTypesDropdownComponent
          name={name}
          modelCountry={modelCountry}
          modelLanguage={modelLanguage}
          isDisabled={isDisabled}
          onSelect={onSelect}
          onShowModal={onShowModal}          
        />
      </AutocompleteContainer>
    </FlexContainer>
  );
}

const AutocompleteContainer = styled.div``;

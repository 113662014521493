import Bowser from 'bowser';
import { globalErrorLogContextStore } from 'features/logging/GlobalErrorLogContextStore';
import { Context } from 'features/logging/sendLogToApi';
import { retrieveRequestIdHeader } from 'services/backofficeIntegration/http/client/useRequestIdHeaderStore';

export const getDefaultLogContext = (): Context => {
  const lastRequestId = retrieveRequestIdHeader() ?? '';
  const browser = Bowser.getParser(window.navigator.userAgent);

  const environmentInfo = `${browser.getBrowserName()} ${browser.getBrowserVersion()} - ${browser.getOSName()} ${browser.getOSVersion()}`;

  const currentPage = window.location.pathname + window.location.search + window.location.hash;

  const currentLoggingContextState = globalErrorLogContextStore.getState();

  return {
    'meta.lastRequestId': lastRequestId,
    'meta.browser': environmentInfo,
    'meta.userAgent': window.navigator.userAgent,
    'meta.page': currentPage,
    // We don't want to introduce too much logic here,
    // therefore not checking if the user is actually on that page.
    'meta.aiWriter.currentDocumentId': currentLoggingContextState.currentAiWriterDocumentId,
    'meta.aiTester.currentDocumentId': currentLoggingContextState.currentAiTesterDocumentId,
    'meta.explorer.currentDocumentId': currentLoggingContextState.currentExplorerDocumentId,
    'meta.customer.id': currentLoggingContextState.customerId,
    'meta.customer.pricingId': currentLoggingContextState.customerPricingId,
    'meta.customer.pricingType': currentLoggingContextState.customerPricingType,
    'meta.customer.hasTeam': currentLoggingContextState.customerHasTeam,
    'meta.customer.isApiCustomer': currentLoggingContextState.customerIsApiCustomer
  };
};

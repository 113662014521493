import { ReactComponent as CrownIcon } from 'assets/icons/crown.svg';
import { useShowRunOutOfCreditsModal } from 'features/pricing/modals/FreeCreditsLimitReachedModal';
import { MouseEvent } from 'react';
import styled from 'styled-components';

type YellowCrownImageWithClickProps = {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  modal?: () => void;
};

export const YellowCrownImageWithClick = ({ variant, modal }: YellowCrownImageWithClickProps) => {
  const outOfCreditsModal = useShowRunOutOfCreditsModal();

  const handleClick = (event: MouseEvent<SVGSVGElement>) => {
    event.preventDefault();
    (modal || outOfCreditsModal)();
  };

  return <YellowCrownImage onClick={handleClick} variant={variant} />;
};

export const YellowCrownImage = styled(CrownIcon)<YellowCrownImageWithClickProps>`
  font-size: ${({ variant, theme }) => variant ? theme.typography[variant]?.fontSize : 'inherit'};
  width: 1em;
  height: 1em;
  color: ${({ theme }) => theme.colors.palette.orange__400};
`;

import { FormControlLabel, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { YellowCrownImageWithClick } from 'components/icons/YellowCrownImage';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useShowUpdateSubscriptionModal } from 'features/updateSubscriptionModal/UpdateSubscriptionModal';
import { FieldCheckbox } from 'features/workflowBlogPostBuilder/steps/FieldCheckbox';
import {
  CheckboxGroup,
  ProLabelContainer
} from 'features/workflowBlogPostBuilder/steps/Step3';
import { MouseEvent } from 'react';
import { withTestId } from 'utils/utils';

type Props = {
  featureDisabled?: boolean;
  imagesDisabled?: boolean;
  videoDisabled?: boolean;
  videoUpgradeRedirect?: boolean;
  isFreePlan: boolean;
};

export const Step3MultimediaSection = ({
  featureDisabled,
  imagesDisabled,
  videoDisabled,
  videoUpgradeRedirect,
  isFreePlan
}: Props) => {
  const isImagesDisabled = imagesDisabled;
  const isVideoDisabled = featureDisabled || videoDisabled;
  const updateSubscriptionModal = useShowUpdateSubscriptionModal();

  const handleUpdateSubscriptionModal = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    return updateSubscriptionModal();
  };

  return (
    <section>
      <FlexContainer gap="small" direction="row">
        <Typography variant="subtitle1">
          <FormattedMessage id="blog_post_builder.steps.elements.multimedia" />
        </Typography>
      </FlexContainer>
      <CheckboxGroup>
        <FormControlLabel
          control={
            <FieldCheckbox fieldName="elements" value="images" disabled={isImagesDisabled} />
          }
          label={
            <Typography variant="body1">
              <FormattedMessage id="blog_post_builder.steps.elements.multimedia.image_check" />
            </Typography>
          }
          {...withTestId('image-element')}
        />
        <FormControlLabel
          control={
            <FieldCheckbox 
            fieldName="elements" 
            value="youtube_video" 
            disabled={isVideoDisabled}
            onClick={videoUpgradeRedirect ? handleUpdateSubscriptionModal : undefined}
            />
          }
          label={
            <ProLabelContainer>
              <Typography variant="body1">
                <FormattedMessage id="blog_post_builder.steps.elements.multimedia.video_check" />
              </Typography>
              {isFreePlan && <YellowCrownImageWithClick variant="h6" modal={updateSubscriptionModal} />}
            </ProLabelContainer>
          }
          {...withTestId('youtube-element')}
        />
      </CheckboxGroup>
    </section>
  );
};

import styled from 'styled-components';

import { ModalCommonProps } from '../../components/modals/RootModal';
import { StripeCheckoutForm } from './start-embedded';

type Props = {
  pricingId: number;
} & ModalCommonProps;

export const StripeEmbeddedModal = ({ pricingId }: Props) => {
  return (
    <ModalBody className="modal-body">
      <StripeCheckoutForm pricingId={pricingId} />
    </ModalBody>
  );
};

const ModalBody = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.spacings.xmedium};
  overflow-y: auto;
  max-height: calc(100vh - (1.75rem * 2));
  padding: 0;
  border-radius: 20px;
`;

import { useQuery } from '@tanstack/react-query';
import { ImageQuality } from 'features/aiImages/AiImagesPage/AiImagesGeneratorContext';
import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { useCompleteTask } from 'features/onboardingJourney/checklist/useOnboardingChecklist';
import { Usage } from 'features/usageIndicator/useGetCurrentUsageByType';
import { useEffect, useState } from 'react';
import AxiosClient from 'services/api';
import { invalidateAiImagesLimitationsQueries } from 'services/backofficeIntegration/http/endpoints/aiImages/httpGetAiImagesLimitations';
import { invalidateCustomerAllLimitationsQueries } from 'services/backofficeIntegration/http/endpoints/customer/httpGetAllLimitations';
import { TASK_TYPE } from 'services/backofficeIntegration/http/endpoints/onboardingChecklist/httpGetChecklist';
import { assertNonNullable } from 'utils/typescript/nonNullable';

export interface ImageGeneratorOptions {
  prompt: string;
  numberOfImages: number;
  width?: number;
  height?: number;
  imageQuality?: ImageQuality;
}

interface SuccessPayload {
  status: true;
  data: {
    image_generations: {
      generation_id: string;
      result: string;
      expires_at: string;
      hash: string;
      url: string;
      limitations: Usage;
    }[];
  };
  message: string;
}

interface ErrorPayload {
  status: false;
  message: string;
}

type Payload = SuccessPayload | ErrorPayload;

export interface ImageGeneratorResult {
  imgSrc: string;
  generationId: string;
  expireDate: Date;
}

export const ImageGeneratorErrors = {
  ERROR_PRICING_REQUESTS_LIMIT_ACHIEVED: 'ERROR_PRICING_REQUESTS_LIMIT_ACHIEVED',
  ERROR_GENERATE_IMAGE_PROMPT_REJECTED: 'ERROR_GENERATE_IMAGE_PROMPT_REJECTED',
  ERROR_GENERATE_IMAGE_IMAGE_NOT_AVAILABLE: 'ERROR_GENERATE_IMAGE_IMAGE_NOT_AVAILABLE',
  ERROR_GENERATE_IMAGE_GENERATION_FAILED: 'ERROR_GENERATE_IMAGE_GENERATION_FAILED',
  UPLOAD_ERROR_CANT_WRITE: 'UPLOAD_ERROR_CANT_WRITE',
  ERROR_IMAGE_GENERATION_FAIR_USAGE_REACHED: 'ERROR_IMAGE_GENERATION_FAIR_USAGE_REACHED',
  ERROR_IMAGE_GENERATION_DAILY_LIMIT_REACHED: 'ERROR_IMAGE_GENERATION_DAILY_LIMIT_REACHED'
};

export function useImageGenerator() {
  const [generatedImages, setGeneratedImages] = useState<ImageGeneratorResult[]>([]);
  const [generateOptions, setGenerateOptions] = useState<ImageGeneratorOptions | undefined>();
  const [doRequest, setDoRequest] = useState(false);

  const completeImagesTask = useCompleteTask(TASK_TYPE.CREATE_IMAGE);

  const {
    data: imagesResponse,
    isFetching,
    error,
    remove
  } = useQuery({
    enabled: doRequest && !!generateOptions,
    retry: false,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryKey: [...makeCurrentUserQueryKeyPrefix(), createRequestKey(generateOptions!)],
    queryFn: async () => {
      assertNonNullable(generateOptions);

      return AxiosClient.post<Payload, Payload>('/image-generations', {
        requestCount: generateOptions.numberOfImages,
        prompt: generateOptions.prompt,
        width: generateOptions.width,
        height: generateOptions.height,
        quality: generateOptions.imageQuality
      }).then(response => {
        if (response.status && response.message === 'success') {
          return response.data.image_generations.map<ImageGeneratorResult>(result => ({
            imgSrc: result.url,
            generationId: result.generation_id,
            expireDate: new Date(result.expires_at)
          }));
        }

        throw new Error(response.message);
      });
    },
    onSettled: () => {
      setDoRequest(false);

      invalidateAiImagesLimitationsQueries();
      invalidateCustomerAllLimitationsQueries();
    },
    onSuccess: () => {
      completeImagesTask();
    }
  });

  async function generateImage(options: ImageGeneratorOptions) {
    setGenerateOptions(options);
    setGeneratedImages([]);
    remove();
    setDoRequest(true);
  }

  useEffect(() => {
    if (imagesResponse) {
      setGeneratedImages(imagesResponse);
    }
  }, [imagesResponse]);

  return { generateImage, generatedImages, isFetching, error };
}

function createRequestKey(options: ImageGeneratorOptions) {
  return JSON.stringify(options);
}

import { FormControlLabel, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { YellowCrownImageWithClick } from 'components/icons/YellowCrownImage';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useShowUpdateSubscriptionModal } from 'features/updateSubscriptionModal/UpdateSubscriptionModal';
import { FieldCheckbox } from 'features/workflowBlogPostBuilder/steps/FieldCheckbox';
import {
  CheckboxGroup,
  ProLabelContainer
} from 'features/workflowBlogPostBuilder/steps/Step3';
import { MouseEvent } from 'react';
import { withTestId } from 'utils/utils';

type Props = {
  isFeatureDisabled?: boolean;
  upgradeRedirect?: boolean;
  isFreePlan: boolean;
};

export const Step3ReferencesSection = ({ isFeatureDisabled, upgradeRedirect, isFreePlan }: Props) => {
  const updateSubscriptionModal = useShowUpdateSubscriptionModal();

  const handleUpdateSubscriptionModal = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    return updateSubscriptionModal();
  };

  return (
    <section>
      <FlexContainer gap="small" direction="row">
        <Typography variant="subtitle1">
          <FormattedMessage id="blog_post_builder.steps.elements.references" />
        </Typography>
      </FlexContainer>
      <CheckboxGroup>
        <FormControlLabel
          control={
            <FieldCheckbox 
            fieldName="elements" 
            value="external_links"
            disabled={isFeatureDisabled}
            onClick={upgradeRedirect ? handleUpdateSubscriptionModal : undefined}
            />
          }
          label={
            <ProLabelContainer>
              <Typography variant="body1">
                <FormattedMessage id="blog_post_builder.steps.elements.references.ref_check" />
              </Typography>
              {isFreePlan && <YellowCrownImageWithClick variant="h6" modal={updateSubscriptionModal} />}
            </ProLabelContainer>
          }
          {...withTestId('reference-element')}
        />
      </CheckboxGroup>
    </section>
  );
};

import { Button, Card, CardContent, CircularProgress, Typography } from '@mui/material';
import { getRoutePath } from 'config/routes';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { LottieComponent } from 'features/lottie/LottieComponent';
import { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import BackgroundDrawing from '../../assets/drawings/doodle.svg';
import IconNeuroflashLogoSVG from '../../assets/neuroflash-logo.svg';
import ErrorAnimation from '../../assets/statusAnimation/Error.lottie';
import CustomerAPI from '../../services/api/customer';

//this boolean is used to prevent a race condition when redirecting the user back from stripe and the
//processing of the payment may take longer than we want to block the user from using the app
//so in those cases we store in this value locally that the user did in fact already finish a successful payment
let paymentSuccessful = false;

export const paymentWasSuccessful = () => paymentSuccessful;

const PaymentCallbackPage = (): ReactElement => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);

  const { sessionId } = useParams<{ sessionId: string }>();

  const [success, setSuccess] = useState<boolean | undefined>();

  useEffect(() => {
    const loadStatus = async () => {
      if (!sessionId) {
        setLoading(false);
        setSuccess(false);
        return;
      }
      const response = await CustomerAPI.getStripeCheckoutSessionStatus(sessionId);
      const successfulStatus = response.status && response.data.status === 'complete';
      setSuccess(successfulStatus);
      setLoading(false);
      if (successfulStatus) {
        paymentSuccessful = true;
        navigate(getRoutePath('paymentSuccess'));
      }
    };
    loadStatus();
  }, [sessionId, navigate]);

  return (
    <Root>
      <Header>
        <LogoImage src={IconNeuroflashLogoSVG} />
      </Header>
      <Body>
        <Card>
          <CenterCard>
            {(loading || success) && <CircularProgress size={50} />}
            {loading && (
              <div>
                <Typography variant="h6">
                  <FormattedMessage id="payment_callback.loading" />
                </Typography>
                <FormattedMessage id="payment_callback.loading_description" />
                ...
              </div>
            )}

            {!loading && !success && (
              <>
                <LottieComponent src={ErrorAnimation} options={{ loop: false, autoplay: true }} />
                <Typography variant="h6">
                  <FormattedMessage id="payment_callback.failure" />
                </Typography>
                <FormattedMessage id="payment_callback.failure_description" />

                <Button variant="contained" onClick={() => navigate(getRoutePath('home'))}>
                  <FormattedMessage id="payment_callback.failure_link_text" />
                </Button>
              </>
            )}

            {!loading && success && (
              <div>
                <Typography variant="h6">
                  <FormattedMessage id="payment_callback.success" />
                </Typography>
                <FormattedMessage id="payment_success.redirect" />
                <Link to={getRoutePath('home')}>
                  <FormattedMessage id="payment_success.link_text" />
                </Link>
                .
              </div>
            )}
          </CenterCard>
        </Card>
      </Body>
      <Footer>
        <BackgroundImage src={BackgroundDrawing} />
      </Footer>
    </Root>
  );
};

const CenterCard = styled(CardContent)`
  padding: 3em;
  max-width: 30em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
`;
const Header = styled.div`
  display: flex;
  justify-content: left;
  padding: 2em;
`;
const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;
const LogoImage = styled.img`
  width: 244px;
`;
const BackgroundImage = styled.img`
  width: 400px;
`;
const Footer = styled.div`
  display: flex;
  justify-content: left;
  max-height: 300px;
  overflow: visible;
`;

export default PaymentCallbackPage;

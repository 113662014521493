import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';
import { usePersonalitySelectorInfiniteQuery } from 'components/personality/usePersonalitySelectorQuery';
import { useStoredActiveConversationId } from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { useCurrentPersonalityQuery } from 'features/aiWriter/AiWriterSidebar/steps/chat/useCurrentPersonalityQuery';
import { getEmbeddingModelDataSelector } from 'features/embeddingModels/store/selectors';
import { logWarning } from 'features/logging/logError';
import { useShowPersonalityCreationModal } from 'features/personality/creation/PersonalityCreationModal';
import {
  PersonalitySelectHandler,
  useShowPersonalityLibraryModal
} from 'features/personality/PersonalityLibraryModal';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { ReactElement } from 'react';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { GetPersonalitiesSuccessDto } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetPersonalities';
import { useAppSelector } from 'store/hooks';

export type PersonalityValue = PersonalityDto | undefined | null;

type ChildrenProps = {
  personalities: PersonalityDto[] | undefined;
  currentPersonality: PersonalityValue;
  isLoading: boolean;

  fetchNextPage: (
    options?: FetchNextPageOptions
  ) => Promise<InfiniteQueryObserverResult<GetPersonalitiesSuccessDto, unknown>>;
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;

  handleMenuOptionChange: (option: OptionValue) => void;
};

type Props = {
  children: ({
    personalities,
    isLoading,
    handleMenuOptionChange,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  }: ChildrenProps) => ReactElement;

  value: PersonalityValue;
  languageModelId: string;

  onChange: (personalityId: PersonalityValue) => void;
};

const manageOption = 'manage';
const noPersonalityOption = 'no personality';
const createOption = 'create';
type OptionValue = typeof manageOption | typeof noPersonalityOption | typeof createOption | number;

export function PersonalityWrapper(props: Props) {
  const embeddingModel = useAppSelector(getEmbeddingModelDataSelector)(props.languageModelId);
  if (!embeddingModel) {
    logWarning('Embedding model not found', 'react', {
      embeddingModelId: props.languageModelId,
      personalityId: props.value?.id
    });
  }

  const { country, language } = embeddingModel;

  const currentConversationId = useStoredActiveConversationId(); // move to chat implementation
  const {
    data: personalitiesData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: personalitiesLoading,
    refetch: refetchPersonalities
  } = usePersonalitySelectorInfiniteQuery({
    language,
    country
  });
  const personalities = personalitiesData?.pages.map(page => page.data).flat(1);

  const currentPersonalityResult = useCurrentPersonalityQuery();

  const showPersonalityLibraryModal = useShowPersonalityLibraryModal();
  const showPersonalityCreationModal = useShowPersonalityCreationModal();

  const currentPersonality = props.value;
  const currentPersonalityId = currentPersonality?.id ?? null;

  // If the current personality is not in the list of personalities, add it
  if (
    personalities &&
    currentPersonality &&
    !personalities.find(p => p.id === currentPersonality.id)
  ) {
    personalities.unshift(currentPersonality);
  }

  const handlePersonalityChange = (targetPersonality: PersonalityDto | null) => {
    if (targetPersonality) {
      trackingWrapper.track('aiWriterSelectPersonas', {
        conversationId: currentConversationId,
        personalityId: targetPersonality.id,
        personalityLabel: targetPersonality?.label,
        personalityCountry: targetPersonality?.country,
        personalityLanguage: targetPersonality?.language
      });
    }

    props.onChange(targetPersonality);
  };

  const handleModalPersonalitySelect: PersonalitySelectHandler = personality => {
    if (personality?.id !== currentPersonalityId) {
      handlePersonalityChange(personality);
    }
  };

  const handleMenuOptionChange = (option: OptionValue) => {
    if (option === manageOption) {
      showPersonalityLibraryModal({
        languageModelId: props.languageModelId,
        onPersonalitySelect: handleModalPersonalitySelect,
        // Update the list of personalities after the modal closes
        onClose: refetchPersonalities
      });
      return;
    }

    if (option === createOption) {
      showPersonalityCreationModal({
        languageModelId: props.languageModelId,
        onPersonalitySelect: handleModalPersonalitySelect,
        // Update the list of personalities after the modal closes
        onClose: refetchPersonalities
      });
      return;
    }

    if (option === noPersonalityOption) {
      handlePersonalityChange(null);
      return;
    }

    const targetPersonality = personalities?.find(p => p.id === option) ?? null;
    handlePersonalityChange(targetPersonality);
  };

  const isLoading =
    personalitiesLoading ||
    /**
     * isFetched flag is true when we have data in cache already
     * isFetching flag is true when API call is in progress
     * we need this because query may be disabled when no personality is selected
     */
    (currentPersonalityResult.isFetching && !currentPersonalityResult.isFetched);

  return (
    <div>
      {props.children({
        personalities,
        isLoading,
        currentPersonality,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        handleMenuOptionChange
      })}
    </div>
  );
}

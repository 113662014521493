import { FormControlLabel, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { YellowCrownImageWithClick } from 'components/icons/YellowCrownImage';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useShowUpdateSubscriptionModal } from 'features/updateSubscriptionModal/UpdateSubscriptionModal';
import { FieldCheckbox } from 'features/workflowBlogPostBuilder/steps/FieldCheckbox';
import {
  CheckboxGroup,
  ProLabelContainer
} from 'features/workflowBlogPostBuilder/steps/Step3';
import { MouseEvent } from 'react';
import { withTestId } from 'utils/utils';

type Props = {
  featureDisabled?: boolean;
  seoDisabled?: boolean;
  seoUpgradeRedirect?: boolean;
  isFreePlan: boolean;
};

export function Step3SeoSection({ featureDisabled, seoDisabled, seoUpgradeRedirect, isFreePlan }: Props) {
  const disabled = featureDisabled || seoDisabled;
  const updateSubscriptionModal = useShowUpdateSubscriptionModal();

  const handleUpdateSubscriptionModal= (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    return updateSubscriptionModal();
  };

  return (
    <section>
      <FlexContainer gap="small" direction="row">
        <Typography variant="subtitle1">
          <FormattedMessage id="blog_post_builder.steps.elements.seo" />
        </Typography>
      </FlexContainer>
      <CheckboxGroup>
        <FormControlLabel
          control={<FieldCheckbox 
            fieldName="elements" 
            value="seobility" 
            disabled={disabled}
            onClick={seoUpgradeRedirect ? handleUpdateSubscriptionModal : undefined}
            />}
          label={
            <ProLabelContainer>
              <Typography variant="body1">
                <FormattedMessage id="blog_post_builder.steps.elements.seo_check" />
              </Typography>
              {isFreePlan && <YellowCrownImageWithClick variant="h6" modal={updateSubscriptionModal} />}
            </ProLabelContainer>
          }
          {...withTestId('optimize-seo-element')}
        />
        <FormControlLabel
          control={
            <FieldCheckbox 
            fieldName="elements" 
            value="what_people_asked" 
            disabled={disabled}
            onClick={seoUpgradeRedirect ? handleUpdateSubscriptionModal : undefined}
            />
          }
          label={
            <ProLabelContainer>
              <Typography variant="body1">
                <FormattedMessage id="blog_post_builder.steps.elements.references.others_check" />
              </Typography>
              {isFreePlan && <YellowCrownImageWithClick variant="h6" modal={updateSubscriptionModal} />}
            </ProLabelContainer>
          }
          {...withTestId('people-ask-element')}
        />
      </CheckboxGroup>
    </section>
  );
}

import { useEffect } from 'react';
import { useNavigate } from 'react-router';

const brandHubIframeUrl = process.env.REACT_APP_BRAND_HUB_V2_URL;

export const IFRAME_ID = 'brand-voice-iframe';

type NavigationMessage = {
  type: 'nav';
  path: string;
};

type WindowSizeMessage = {
  type: 'size';
  width: number;
  height: number;
};

type IframeMessage = NavigationMessage | WindowSizeMessage;

export function useIframeMessageListener() {
  const navigate = useNavigate();

  useEffect(() => {
    const onEventMessage = (evt: MessageEvent) => {
      if (evt.origin !== brandHubIframeUrl) {
        return;
      }

      const data = evt.data as IframeMessage;

      if (data.type === 'nav') {
        navigate(data.path);
      }

      if (data.type === 'size') {
        // if needed, set the size of the iframe
      }
    };

    window.addEventListener('message', onEventMessage);
    return () => {
      window.removeEventListener('message', onEventMessage);
    };
  }, [navigate]);
}

export const routeIframe = (path: string) => {
  const iFrame = document.getElementById(IFRAME_ID) as HTMLIFrameElement;
  iFrame?.contentWindow?.postMessage({ type: 'nav', path }, brandHubIframeUrl);
};

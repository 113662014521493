import axios, { AxiosInstance } from 'axios';
import { createUniqueTransactionId } from 'features/logging/createUniqueTransactionId';
import { logAxiosError } from 'features/logging/logAxiosError';
import { logWarning } from 'features/logging/logError';
import { transactionIdHeaderName } from 'features/logging/transactionIdHeaderName';

const captureUnknownError = (error: unknown) => {
  // We expect the error to be an instance of Error, but if it's not, we'll create one
  const errorObjectOrMessage = error instanceof Error ? error : new Error(String(error));

  return logWarning(errorObjectOrMessage, 'unknown');
};

export const withErrorLogging = (client: AxiosInstance) => {
  client.interceptors.request.use(config => {
    config.headers[transactionIdHeaderName] = createUniqueTransactionId();
    return config;
  });

  client.interceptors.response.use(undefined, (error: unknown) => {
    if (axios.isAxiosError(error)) {
      logAxiosError(error);
    } else {
      captureUnknownError(error);
    }

    throw error;
  });
};

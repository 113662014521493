import { ReactComponent as DocumentsIcon } from 'assets/sidebar/icon-bonus.svg';
import { getRoutePath } from 'config/routes';
import { SidebarNavItem } from 'features/pageSidebar/sidebarItems/SidebarNavItem';
import { useSidebarStore } from 'features/pageSidebar/useSidebarStore';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { PricingPlan } from 'features/pricing/types';
import React from 'react';
import gtmIds from 'services/tracking/GTMIds';

const pricingPlansWithDisabledReferral: PricingPlan[] = [
  PricingPlan.business,
  PricingPlan.enterprise
];

export const SidebarItemReferralBonusPage = (): React.ReactElement | null => {
  const expandSidebar = useSidebarStore(state => state.expandSidebar);
  const { currentPricingPlan } = useSubscriptionState();

  if (pricingPlansWithDisabledReferral.includes(currentPricingPlan.type)) {
    return null;
  }

  return (
    <SidebarNavItem
      id={gtmIds.sidebar.referralBonusPageButton}
      icon={<DocumentsIcon />}
      name="layout.sidebar.actions.bonus_referral_button"
      tooltip="layout.sidebar.actions.bonus_referral_button"
      to={getRoutePath('bonusReferral')}
      onClick={expandSidebar}
    />
  );
};

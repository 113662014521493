import { CircularProgress } from '@mui/material';
import { YellowCrownImageWithClick } from 'components/icons/YellowCrownImage';
import { useShowUpdateSubscriptionModal } from 'features/updateSubscriptionModal/UpdateSubscriptionModal';
import { ComponentProps, forwardRef, HTMLAttributes } from 'react';
import styled from 'styled-components';

type CircularProgressPropsWithoutValue = Omit<ComponentProps<typeof CircularProgress>, 'value'>;

type Props = HTMLAttributes<HTMLDivElement> & {
  withBackground?: boolean;
  CircularProgressProps?: CircularProgressPropsWithoutValue;
  value: ComponentProps<typeof CircularProgress>['value'];
  animationRadius?: number;
};

export const RoundedCircularProgressWithCrown = forwardRef<HTMLDivElement, Props>(
  ({ withBackground = true, CircularProgressProps, ...props }, ref) => {
    const { animationRadius } = props;
    const updateSubscriptionModal = useShowUpdateSubscriptionModal();
    
    return (
      <Root ref={ref} {...props}>
        {withBackground && (
          <BackgroundCircularProgress
            variant="determinate"
            {...CircularProgressProps}
            value={100}
          />
        )}

        <AnimatedCircularProgress
          variant="determinate"
          {...CircularProgressProps}
          value={animationRadius ? 0 : 60}
          radius={animationRadius}
        />
        <CrownContainer>
          <YellowCrownImageWithClick modal={updateSubscriptionModal} />
        </CrownContainer>
      </Root>
    );
  }
);

const AnimatedCircularProgress = styled(CircularProgress)<{ radius?: number }>`
  stroke-linecap: round;

  ${({ radius }) =>
    radius &&
    `
      & .MuiCircularProgress-circle {
        animation: dashAnimation 3s linear forwards;
      }

      @keyframes dashAnimation {
        from {
          stroke-dasharray: 0 ${radius * 2 * Math.PI};
        }
        to {
          stroke-dasharray: ${radius * Math.PI * 2 * 0.6} ${radius * Math.PI * 2 * 0.4};
        }
      }
    `}
`;

const Root = styled.div`
  position: relative;
  display: inline-flex;
`;

const CrownContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const RoundedCircularProgress = styled(CircularProgress)`
  stroke-linecap: round;
`;

const BackgroundCircularProgress = styled(RoundedCircularProgress)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.2;
`;

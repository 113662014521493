import { Button, Typography } from '@mui/material';
import leftBanner from 'assets/trial.svg';
import cx from 'classnames';
import { useModal } from 'components/modals';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { logError } from 'features/logging/logError';
import { useAvailablePlans } from 'features/pricing/hook/useAvailablePlans';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { ErrorBoundary } from 'features/react-error-boundary/ErrorBoundary';
import { StripeEmbeddedModal } from 'features/stripe-portal/StripeEmbeddedModal';
import { paymentWasSuccessful } from 'pages/paymentCallback';
import { ReactElement, useState } from 'react';
import ReactModal from 'react-modal';
import { useLocation } from 'react-router';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useAppThemeStore } from 'styles/new-theme/useAppThemeStore';
import { withTestId } from 'utils/utils';


const NUMBER_OF_PERKS = 4;

function trialModalClosed(key: string) {
  localStorage.setItem(key, new Date().valueOf().toString());
}

function trialModalHasBeenClosedLessThanXHoursAgo(key: string, x: number): boolean {
  const closedAt = localStorage.getItem(key);
  if (!closedAt) {
    return false;
  }
  const closedAtMS = parseInt(closedAt);
  const now = new Date().valueOf();
  const xHoursAgo = now - x * 60 * 60 * 1000;

  return closedAtMS > xHoursAgo;
}

export const trialModalExcludedPages = [
  '/onboarding',
  '/login',
  '/free',
  '/payment-success',
  '/payment-callback',
  '/register',
  '/reset-password'
];

export const TrialModal = (): ReactElement | null => {
  const { showModal } = useModal();
  const themeMode = useAppThemeStore(state => state.themeMode);
  const { currentPricingPlan, currentSubscriptionState } = useSubscriptionState();
  const customerId = useAppSelector(state => state.customer.id);
  const enforceFreeTrial = useAppSelector(state => state.customer.enforceFreeTrial);
  const trialUsed = useAppSelector(state => state.customer.trialUsed);
  const preferredPricingId = useAppSelector(state => state.customer.preferredPricingId);
  const [modalClosed, setModalClosed] = useState<boolean>(false);
  const { data } = useAvailablePlans({ period: 'month' });
  const { starterPlan, proPlan } = data ?? {};
  const location = useLocation();

  const modalClosedAtKey = `trial_modal_closed_at-${customerId}`;

  const primaryAction = () => {
    if (!proPlan) {
      logError('proPlan is undefined', 'react', {
        proPlan: proPlan
      });
    } else {
      showModal('STRIPE_EMBEDDED_PAYMENT', {
        size: 1100,
        pricingId: proPlan.id
      });
    }
  };

  const secondaryAction = () => {
    if (enforceFreeTrial) {
      if (!starterPlan) {
        logError('starterPlan is undefined', 'react', {
          starterPlan: starterPlan
        });
      } else {
        showModal('STRIPE_EMBEDDED_PAYMENT', {
          size: 1100,
          pricingId: starterPlan.id
        });
      }
    } else if (enforceFreeTrial === false || enforceFreeTrial === 0) {
      setModalClosed(true);
      trialModalClosed(modalClosedAtKey);
    } else {
      logError('enforceFreeTrial is neither true nor false', 'react', {
        enforceFreeTrial: enforceFreeTrial
      });
    }
  };

  //hide this modal when customer is not authenticated
  if (!customerId) {
    return null;
  }

  //prevent race condition if the payment is not processed fast enough
  if (paymentWasSuccessful()) {
    return null;
  }

  //hide this modal on onboarding and login pages
  if (trialModalExcludedPages.findIndex(path => location.pathname.startsWith(path)) > -1) {
    return null;
  }

  //while we are still loading the subscription state, no modal should be displayed
  if (!currentSubscriptionState) {
    return null;
  }

  //if the customer already has a paid subscription, we don't need to ask him to create one
  if (
    currentPricingPlan.type !== 'free' &&
    (currentSubscriptionState?.status === 'subscribed' ||
      currentSubscriptionState?.status === 'grace' ||
      currentSubscriptionState?.status === 'on trial' ||
      currentSubscriptionState?.status === 'on trial grace')
  ) {
    return null;
  }

  //if the trial has already been used, we don't want to offer it again
  if (trialUsed) {
    return null;
  }

  //if the optional modal has already been closed before, the customer does not see it again for a certain timeframe
  if (!enforceFreeTrial && trialModalHasBeenClosedLessThanXHoursAgo(modalClosedAtKey, 48)) {
    return null;
  }

  let modalBody;

  //redirect directly to stripe if the customer has a specific pricingId
  if (preferredPricingId) {
    modalBody = <StripeEmbeddedModal pricingId={preferredPricingId} closeModal={() => undefined} />;
  } else {
    modalBody = (
      <ModalBody>
        <Content>
          <LeftImage src={leftBanner} />
          <RightSide>
            <LargeHeader>
              <FormattedMessage id="trial_modal.title" />
            </LargeHeader>
            <FormattedMessage id="trial_modal.description" />
            <Typography variant="h6">
              <FormattedMessage id="trial_modal.benefits_title" />
            </Typography>

            <ul>
              {Array.from({ length: NUMBER_OF_PERKS })
                .map((_, index) => index)
                .map(index => (
                  <li key={index}>
                    <strong>
                      <FormattedMessage
                        id={`trial_modal.benefits_${index}_title`}
                      ></FormattedMessage>
                    </strong>{' '}
                    <FormattedMessage
                      id={`trial_modal.benefits_${index}_description`}
                    ></FormattedMessage>
                  </li>
                ))}
            </ul>

            <Button fullWidth={true} variant="contained" color="primary" onClick={primaryAction} {...withTestId('start-trial-button')}>
              <FormattedMessage
                id={`trial_modal.${enforceFreeTrial ? 'required' : 'optional'}.main_action_text`}
              />
            </Button>
            <CenteredText>
              <OtherOptionLink variant="body2" color="textSecondary" onClick={secondaryAction} {...withTestId('reject-trial-button')}>
                <FormattedMessage
                  id={`trial_modal.${enforceFreeTrial ? 'required' : 'optional'}.other_option_text`}
                />
              </OtherOptionLink>
            </CenteredText>
          </RightSide>
        </Content>
      </ModalBody>
    );
  }
  
  return (
    <ReactModal
      isOpen={!modalClosed}
      bodyOpenClassName="modal-open"
      overlayClassName={cx('modal fade', 'show')}
      className={cx('modal-dialog', 1100 && 'modal-1100')}
      shouldCloseOnOverlayClick={!enforceFreeTrial}
      shouldCloseOnEsc={!enforceFreeTrial}
      shouldFocusAfterRender={true}
      onRequestClose={() => setModalClosed(!enforceFreeTrial)}
    >
      <ErrorBoundary>
        <div className={cx('modal-content', themeMode)}>
          <ModalWrapper>{modalBody}</ModalWrapper>
        </div>
      </ErrorBoundary>
    </ReactModal>
  );
};

const LargeHeader = styled(Typography).attrs({ variant: 'h1' })`
  font-size: xxx-large !important;
`;

const LeftImage = styled.img`
  border-radius: 24px 0px 0px 24px;
`;

const ModalWrapper = styled.div`
  z-index: 1;
`;

const ModalBody = styled.div`
  position: relative;
  overflow-y: auto;
  padding-right: 1.75rem;
  max-height: calc(100vh - 3.5rem);
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 1em;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
`;

const CenteredText = styled.div`
  text-align: center;
`;

const OtherOptionLink = styled(Typography)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};

  :hover {
    text-decoration: underline;
  }
`;

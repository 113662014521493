import { Divider, Link, Typography } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { YellowCrownImage } from 'components/icons/YellowCrownImage';
import { useModal } from 'components/modals';
import PlanConfirmButton from 'components/profile/components/pricing-plan-card/PlanConfirmButton';
import PlanPerkList from 'components/profile/components/pricing-plan-card/PlanPerkList';
import { usePricingDowngradePreventionModal } from 'components/profile/components/pricing-plan-card/PricingDowngradePreventionModal';
import { usePlanConfirmMutation } from 'components/profile/components/pricing-plan-card/usePlanConfirmMutation';
import { useTeamDowngradePrevention } from 'components/profile/components/pricing-plan-card/useTeamDowngradePrevention';
import { CouponCode } from 'features/pricing/CouponCode';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { PricingInPricingCard } from 'features/pricing/PricingInPricingCard';
import { isTypeBusinessOrProPlan, isTypeBusinessPlan, PricingPlan } from 'features/pricing/types';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { ACLPricing, CustomerActiveSubscriptionState } from 'services/api/customer/types';
import styled from 'styled-components';
import { resetLink } from 'styles/styledComponents/resets';
import useTr from 'utils/hooks/useTr';

interface Props {
  pricingPlan: ACLPricing;
  isBillingInfoPresent: boolean;
  isActivePlan: boolean;
  subscriptionState: CustomerActiveSubscriptionState;
  coupon?: CouponCode;
  fromPopupModal?: boolean;
  detailsComponent?: React.ReactNode;
  monthlyCost?: string;
}

// #tech-debt: https://app.clickup.com/t/862j5ed7u
export const PricingPlanCard = ({
  pricingPlan,
  isBillingInfoPresent,
  isActivePlan,
  subscriptionState,
  coupon,
  monthlyCost
}: Props): ReactElement => {
  const { showModal, hideModal } = useModal();
  const translate = useTr();
  const businessPlanLink = translate('pricing_plan.contact_expert.link');

  const { mutate: confirmPlan, isLoading } = usePlanConfirmMutation();

  const { isDowngradePrevented, isLoading: isDowngradePreventionLoading } =
    useTeamDowngradePrevention(pricingPlan);

  const showPricingDowngradePreventionModal = usePricingDowngradePreventionModal();
  const { currentPricingPlan, currentSubscriptionState } = useSubscriptionState();

  const handlePlanConfirmClick = async () => {
    if (isTypeBusinessPlan(pricingPlan)) {
      window.open(businessPlanLink, '_blank');
      hideModal();
      return;
    }

    if (isDowngradePrevented) {
      showPricingDowngradePreventionModal();
      return;
    }

    const paidPlanIsActive =
      currentSubscriptionState?.status === 'subscribed' &&
      currentSubscriptionState?.paymentStatus === 'fulfilled' &&
      !currentPricingPlan.isFree;

    //we can only use the embedded checkout if this customer does not yet have a plan
    //otherwise if it's not a new subscription but a change to an existing plan, we have to use the redirect
    //to the stripe customer portal
    if (!paidPlanIsActive) {
      showModal('STRIPE_EMBEDDED_PAYMENT', {
        size: 1100,
        pricingId: pricingPlan.id
      });
    } else {
      confirmPlan({
        pricingId: pricingPlan.id,
        isActivePlan,
        subscriptionState,
        onComplete: () => {
          hideModal();
        }
      });
    }
  };

  return (
    <PlanCard $isActive={isActivePlan}>
      {pricingPlan.type === PricingPlan.pro && (
        <StyledBadge>
          <Typography variant="body1Bold">
            <FormattedMessage id="profile.plan.popular" />
          </Typography>
        </StyledBadge>
      )}
      <CenterAllignedContainer>
        <Typography variant="h5">{pricingPlan.name}</Typography>{' '}
        {isTypeBusinessOrProPlan(pricingPlan) && <YellowCrownImage variant="h5" />}
      </CenterAllignedContainer>
      <SublineTypography variant="body1">
        <FormattedMessage id={`profile.plan.${pricingPlan.type}_subline`} />
      </SublineTypography>
      <CenterAllignedContainer>
        <PricingInPricingCard pricing={pricingPlan} discount={coupon} monthlyCost={monthlyCost} />
        {!pricingPlan.isFree && (
          <ColoredTypography variant="caption" color="textSecondary">
            <FormattedMessage
              id={`profile.plan.${pricingPlan.type}_subscription_period`}
              defaultMessage=""
            />
          </ColoredTypography>
        )}
      </CenterAllignedContainer>
      <PlanConfirmButton
        isBillingInfoPresent={isBillingInfoPresent}
        isActivePlan={isActivePlan}
        isLoading={isLoading || isDowngradePreventionLoading}
        subscriptionState={subscriptionState}
        isFreePlan={pricingPlan.isFree}
        onCtaClick={handlePlanConfirmClick}
        isTrial={pricingPlan.isTrial}
        isBusinessPlan={isTypeBusinessPlan(pricingPlan)}
      />
      <ColoredTypography variant="caption" color="textDisabled">
        <FormattedMessage id={`profile.plan.${pricingPlan.type}_credit_card_hint`} />
      </ColoredTypography>
      <Divider />
      <FlexContainer gap="two">
        <PlanPerkList planType={pricingPlan.type} />
      </FlexContainer>
    </PlanCard>
  );
};

const StyledBadge = styled.div`
  background: ${({ theme }) => theme.colors.secondaryColorMain};
  color: ${({ theme }) => theme.colors.commonWhiteMain};
  display: flex;
  padding: ${({ theme }) => `${theme.spacings.one} ${theme.spacings.three}`};
  width: fit-content;
  position: absolute;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius.two};
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -15px;
  padding-top: 1px;
  z-index: 2;
`;

export const PricingLink = styled.a`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #35353f;
`;

export const PlanCard = styled(FlexContainer)<{ $isActive?: boolean }>`
  padding: ${({ theme }) => theme.spacings.three};
  gap: ${({ theme }) => theme.spacings.three};
  background: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.primaryColorHover : 'transparent'};
  border-radius: ${({ theme }) => theme.borderRadius.one};

  border: 1px solid ${({ theme }) => theme.colors.divider};

  // Prepare for 3 plans but also allow more
  flex-basis: 33%;
  text-align: center;
  z-index: 1;
  position: relative;
`;

export const HeadlineContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.three};
`;

export const SublineContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.three};
`;

export const SublineTypography = styled(Typography)`
  position: relative;
  min-height: 48px;
  color: ${({ theme }) => theme.colors.textSecondary};
`;

export const CenterAllignedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.one};
`;

export const Headline = styled(Typography)`
  display: flex;
  align-items: center;
  /**
    #tech-debt: https://app.clickup.com/t/862j5ed7u 
    the height is hardcoded due to power plans card 
    and have to match the height of the dropdown   
  */
  height: 2.6rem;
  justify-content: center;
  color: ${({ theme }) => theme.colors.commonBrandColorsBrandOrange};
`;

export const StyledLink = styled(Link)`
  ${resetLink};

  cursor: pointer;

  ${({ theme }) => ({
    ...theme.typography.body2
  })}
  color: ${({ theme }) => theme.colors.textPrimary};
  text-decoration: underline;
`;

import { logWarning } from 'features/logging/logError';
import { UpdateRunningModularWorkflowStepsApiResponseError } from 'services/backofficeIntegration/http/endpoints/modularWorkflow/httpUpdateRunningModularWorkflowSteps';
import { assertNonNullable } from 'utils/typescript/nonNullable';

export type RunnerError = {
  stepId: number;
  inputIndex: number;
  errorMessage: string | string[];
};

export const getRunnerErrorsFromResponse = (
  response: UpdateRunningModularWorkflowStepsApiResponseError
): RunnerError[] => {
  if (!response.data) {
    return [];
  }

  return Object.keys(response.data)
    .map<RunnerError | null>(errorKey => {
      assertNonNullable(response.data);

      // Parse step index and input index from error key
      // errorKey is in the format: steps.<stepId>.inputs.<index>.value
      const match = errorKey.match(/steps\.(\d+)\.inputs\.(\d+)\.value/);
      if (!match) {
        logWarning(new Error('Runner: failed to parse error key'), 'modular_workflow', {
          errorKey,
          errors: JSON.stringify(response.data)
        });
        return null;
      }

      const stepId = parseInt(match[1]);
      const inputIndex = parseInt(match[2]);
      const errorMessage = response.data[errorKey];

      return {
        stepId,
        inputIndex,
        errorMessage
      };
    })
    .filter((value: RunnerError | null): value is RunnerError => value !== null);
};
